@keyframes fadeIn {
  from {
    transform: translateY(1%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fadeInDeep {
  from {
    transform: translateY(10%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeOutDeep {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(10%);
    opacity: 0;
  }
}


.fade-in {
  animation-delay: 0s;
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
}

.fade-in-deep {
  animation-delay: 0.5s;
  animation-name: fadeInDeep;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
}

.fade-out-deep {
  animation-delay: 0s;
  animation-name: fadeOutDeep;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
}
