.navbar-toggler {
  outline: none !important;
  box-shadow: none !important;
  border: none;

  &:focus-visible {
    outline: none !important;
    box-shadow: none !important;
  }

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.toggler-icon {
  display: block;
  width: 30px;
  height: 2px;
  background-color: white;
  margin: 6px 0;
  transition: all 0.4s ease;
}

.navbar-toggler.open .top-bar {
  transform: translateY(8px) rotate(45deg);
}

.navbar-toggler.open .middle-bar {
  opacity: 0;
}

.navbar-toggler.open .bottom-bar {
  transform: translateY(-8px) rotate(-45deg);
}

.navbar-toggler-icon.open {
  transform: rotate(45deg);
}

.navbar-toggler.open .navbar-toggler-icon {
  background-image: none;
}
