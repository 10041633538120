@import "../../node_modules/bootstrap/scss/bootstrap";
@import "fade-in";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

$neon: #ED1C24;

$neonShadow: 0 0 5px #fff,
0 0 11px #fff,
0 0 19px $neon,
0 0 40px $neon,
0 0 80px $neon,
0 0 90px $neon;

$blackish1: #010101;
$blackish2: #003242;
$breakPoint: 992px;

html, body {
  max-width: 100%;
  background-color: $blackish1;
}

a {
  text-decoration: none;
  color: white;
}

html::-webkit-scrollbar {
  display: none;
}

html {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body {
  margin: 0;
  color: white;
  font-family: "Montserrat", "Helvetica", sans-serif;
  letter-spacing: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $blackish1 0 0 no-repeat padding-box;
  padding-top: 100px;
  line-height: 1.5;
}

* {
  user-select: none;
}

img {
  width: 100%;
}

/* customized bootstrap */

.nav-link {
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
  line-height: 20px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
  text-align: center;

  &:hover {
    color: gray;
  }

  img {
    color: gray;

    &:hover {
      filter: brightness(50%) saturate(100%) contrast(100%);
    }
  }
}

.navbar-custom {
  height: 100px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
 // background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0));
}


h6 {
  cursor: pointer;
}
/* end customized bootstrap */

@keyframes flicker {
  0%, 19%, 21%, 23%, 25%, 54%, 56%, 100% {
    text-shadow: $neonShadow;
    opacity: 1;
  }
  20%, 24%, 55% {
    opacity: 0.5;
    text-shadow: none;
  }
}

h4 {
  font-weight: bold;
  text-shadow: 1px 1px 10px $neon;
  margin-bottom: 14px;
}

.hidden {
  opacity: 0;
}

.active {
  text-shadow: $neonShadow;
}

p {
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.54px;
  line-height: 30.6px;

  .right {
    text-align: left;
    border: 1px solid red;
  }

  .left {
    text-align: left;
  }
}

.home {
  margin-right: 70px;
  display: flex;
  flex-direction: column;

  img {
    width: 108px;
    height: 108px;
  }
}

.social-logo {
  img {
    width: 44px;
    height: 44px;
  }
  p {
    text-shadow: $neonShadow;
  }
}


@media (max-width: $breakPoint) {
  #responsive-navbar-nav {
    animation-name: fadeIn;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: black;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 0px 5px 5px rgba(44, 44, 44, 128);
  }
}

.mobile-only {
  display: block;

  .one-section {
    h4 {
      padding-top: 24px;
    }
  }

  .footer {
    .nav-link {
      margin-left: 0;
    }
  }
}

.desktop-only {
  display: none;
}

/* Styles for tablets and desktops */
@media (min-width: $breakPoint) {
  .mobile-only {
    display: none;
  }
  .desktop-only {
    display: block;
  }
}

// Lightbox hack
.yarl__slide {
  padding: 0 !important;
}

// Youtube loader
.gradient-placeholder {
  position: absolute;
  width: 100%;
  height: 0;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  overflow: hidden;
}

.gradient-placeholder-animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #002534, #000000);
  background-size: 400% 400%;
  animation: gradient-placeholder-animation 2s ease-in-out infinite;
  animation-fill-mode: both;
}

@keyframes gradient-placeholder-animation {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

h6 {
  line-height: 1.5;
}

.required::after {
  content: '*';
  color: red;
  margin-left: 2px;
}

.description {
  a {
    text-decoration: underline;
  }
  white-space: pre-wrap;
}

h5 {
  line-height: 1.7;
}


.btn-primary {
  background-color: #003242;
  border: 1px solid #00B0D8;
}

.btn-primary:hover {
  background-color: #00B0D8;
}

.nav-link {
  div.learn-more {
    width: 100%;
    text-align: center;
    font-size: 16px !important;
  }
}

a.privacy-policy {
  text-decoration: underline;
}

.footer-container {
  border-top: 1px solid rgba(255,255,255,0.1);
  padding-top: 6px;
  p {
    font-size: 14px;
  }
  margin: 0;
}

.review-container {
  margin-top: -24px;
}

.bottom-border {
  border-bottom: 1px solid #ced4da; /* Customize border properties as needed */
}

.row-odd {
  background-color: #555555;
}

.modal-content {
  background-color: $blackish2;
}

.recall-iframe {
  height: 85vh; /* 100% of the viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
}
